import { APIClient } from "../../helpers/apiClient";
import {
  SET_ACTIVE_CONTACT,
  UPDATE_IFRAME,
  TYPE_UPDATE_IFRAME,
  SET_TAG_LVL,
} from "./constants";

import { sendTemplate, setChats } from "../actions";
import {
  getChats,
  setChatFilter,
  updateContactInChatsList,
  refreshContactChats,
  setChatAgentFilter,
  openContactEditModal,
} from "../chat/actions";
import { updateMembers } from "../members/actions";
import axios from "axios";
const apiClient = new APIClient();
const { create, get } = apiClient;

export const updateIframe = (value) => ({
  type: UPDATE_IFRAME,
  payload: value,
});

export const typeUpdateIframe = (value) => ({
  type: TYPE_UPDATE_IFRAME,
  payload: value,
});


export const editContact =
  (contact, contactTags, contactData) => async (dispatch, getState) => {
    try {
      await create("/contacts/new/auth", contact);

      const currentGroupId = getState().Group.currentGroup?.id;
      const currentContact = getState().Contact.activeContact;
      if (currentGroupId === contact?.groupId) {
        dispatch(setChatFilter(""));
        dispatch(getChats(true, true));
        dispatch(
          setActiveContact({
            ...currentContact,
            tagIdList: contactTags?.map((item) => item.value),
            name: contactData?.name,
            countryCode: contactData?.countryCode,
            email: contactData?.email,
            brand: contactData?.brand,
            phone: contactData?.phone,
            custom_1: contactData?.custom_1,
            custom_2: contactData?.custom_2,
            custom_3: contactData?.custom_3,
            custom_4: contactData?.custom_4,
            custom_5: contactData?.custom_5,
            custom_6: contactData?.custom_6,
            custom_7: contactData?.custom_7,
            custom_8: contactData?.custom_8,
            custom_9: contactData?.custom_9,
            custom_10: contactData?.custom_10,
            custom_11: contactData?.custom_11,
            custom_12: contactData?.custom_12,
            custom_13: contactData?.custom_13,
            custom_14: contactData?.custom_14,
            custom_15: contactData?.custom_15,
            custom_16: contactData?.custom_16,
            custom_17: contactData?.custom_17,
            custom_18: contactData?.custom_18,
            custom_19: contactData?.custom_19,
            custom_20: contactData?.custom_20,
          })
        );
        dispatch(openContactEditModal(false));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const setActiveContact = (contact) => ({
  type: SET_ACTIVE_CONTACT,
  payload: contact,
});

export const saveAgentAssociation = (contact, agentId) => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    let membersOfGroup = getState().Members.members;
    let agent = membersOfGroup?.find(member => member.id == agentId);
    if (contact && agent && currentGroup) {
      const response = await create(`/contacts/${contact.id}/assign`, {
        groupId: currentGroup.id,
        apiKey: currentGroup.apiKey,
        userId: agent.id,
        data: {
          phone: contact.phone,
        }
      });

      const chatList = getState().Chat.chatsList;
      const activeContact = getState().Contact.activeContact;
      membersOfGroup = getState().Members.members;
      agent = membersOfGroup?.find(member => member.id == agentId);

      if (agent && !agent.tagId) {
        const i = membersOfGroup.findIndex(member => member.id == agent.id);
        const newMembersOfGroup = [...membersOfGroup];
        newMembersOfGroup[i].tagId = response.userTagId;
        dispatch(updateMembers(newMembersOfGroup));
      }
      const chatIdx = chatList.findIndex(j => j.id == contact.id);
      if (chatIdx != -1) {
        const newChatList = [...chatList];
        newChatList[chatIdx].userTagId = agent.tagId;
        newChatList[chatIdx].userTagIds = response.userTagIds;
        dispatch(setChats(newChatList));
      }
      if (activeContact?.id === response.id) {
        dispatch(setActiveContact({
          ...activeContact,
          userTagId: response.userTagId,
          userTagIds: response.userTagIds,
        }));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const updateChatStatusSetByUser = (archiveChatStatus) => async (dispatch, getState) => {
  try {
    const params = archiveChatStatus ? { archiveChatStatusSetByUser: true } : {};
    const activeContactId = getState().Contact.activeContact?.id;
    const response = await create(`/contacts/${activeContactId}/chatstatus`, params);
    const activeContactIdAfterReq = getState().Contact.activeContact?.id;
    dispatch(updateContactInChatsList(response));
    if (activeContactIdAfterReq === response?._id) dispatch(setActiveContact(response));
  } catch (error) {
    console.log(error);
  }
}

export const unassignContactFromAgent = () => async (dispatch, getState) => {
  try {
    const activeContactId = getState().Contact.activeContact?.id;
    const response = await create(`/contacts/${activeContactId}/unassign`);
    const activeContactIdAfterReq = getState().Contact.activeContact?.id;
    dispatch(updateContactInChatsList(response));
    if (activeContactIdAfterReq === response?._id) dispatch(setActiveContact(response));
  } catch (error) {
    console.log(error);
  }
}

export const changeChatStatusTag = (chatStatusTag) => async (dispatch, getState) => {
  try {
    const activeContact = getState().Contact.activeContact;
    const response = await create(`/contacts/${activeContact.id}/chat-status-tag`, { chatStatusTags: chatStatusTag });
    if (!response || !Array.isArray(response.chatStatusTags) || response.chatStatusTags.length == 0) {
      throw Error(`Saved contact has not chat status tag ${chatStatusTag}`);
    }
    const activeContactAfterReq = getState().Contact.activeContact;
    const chatsList = getState().Chat.chatsList;

    if (activeContactAfterReq?._id === activeContact?._id)
      dispatch(setActiveContact({
        ...activeContactAfterReq,
        chatStatusTags: response.chatStatusTags,
      }));

    const foundChat = chatsList.find((chat) => chat._id === activeContact?._id);
    if (foundChat) dispatch(updateContactInChatsList({ ...foundChat, chatStatusTags: response.chatStatusTags }));

  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getActiveContact = (contactId) => async (dispatch, getState) => {
  try {
    const chatsList = getState().Chat.chatsList;
    const foundContact = chatsList.find(c => c.id === contactId);
    if (foundContact) dispatch(setActiveContact(foundContact));
    else {
      const response = await get(`/contacts/${contactId}`);
      const currentChat = getState().Chat.currentChat;
      if (Array.isArray(currentChat) && currentChat.length > 0 && currentChat[0].contactId === response?._id)
        dispatch(setActiveContact(response));
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const contactUnassigned = (payload) => async (dispatch, getState) => {
  try {
    const permissionsCurrentGroup = getState().Auth.permissionsCurrentGroup;
    if (permissionsCurrentGroup?.role === 'ROLE_PUBLISHER'){
      dispatch(getChats(true));
      return;
    }
    const chatsList = getState().Chat.chatsList;
    const activeContact = getState().Contact.activeContact;
    const { id, unassignedAt } = payload;

    if (activeContact?._id === id) {
      const _activeContact = { ...activeContact };
      delete _activeContact.userTagId;
      if (Array.isArray(_activeContact.userTagIds) && _activeContact.userTagIds.length > 0)
        _activeContact.userTagIds[_activeContact.userTagIds.length - 1].unassignedAt = unassignedAt;
      dispatch(setActiveContact(_activeContact));
    }

    const foundChat = chatsList?.find((chat) => chat._id === id);
    if (foundChat) {
      delete foundChat.userTagId;
      if (Array.isArray(foundChat.userTagIds) && foundChat.userTagIds.length > 0)
        foundChat.userTagIds[foundChat.userTagIds.length - 1].unassignedAt = unassignedAt;
      dispatch(setChats([...chatsList]));
    }
  } catch (error) {
    console.log(error.message);
  }
}

export const contactReassigned = (payload) => async (dispatch, getState) => {
  try {
    const permissionsCurrentGroup = getState().Auth.permissionsCurrentGroup;
    if (permissionsCurrentGroup?.role !== 'ROLE_ADMI') return;

    const chatsList = getState().Chat.chatsList;
    const activeContact = getState().Contact.activeContact;
    const { id, userTagId, assignedAt } = payload;

    if (!userTagId) return;

    if (activeContact?._id === id) {
      let userTagIds = [...(activeContact.userTagIds || []), { userTagId, assignedAt }];
      const _activeContact = { ...activeContact, userTagId, userTagIds };
      dispatch(setActiveContact(_activeContact));
    }

    const foundChat = chatsList?.find((chat) => chat._id === id);
    if (foundChat) {
      let userTagIds = [...(foundChat.userTagIds || []), { userTagId, assignedAt }];
      foundChat.userTagId = userTagId;
      foundChat.userTagIds = userTagIds;
      dispatch(setChats([...chatsList]));
    }
  } catch (error) {
    console.log(error.message);
  }
}

export const unassignedContactsCompleted = (payload) => async (dispatch, getState) => {
  try {
    const user = getState().Auth.user;
    const { userDeassigned: incomingUserId } = payload;
    if (user?._id === incomingUserId) return;

    const permissionsCurrentGroup = getState().Auth.permissionsCurrentGroup;
    const members = getState().Members.members;
    const agentIdInChatAgentFilter = getState().Chat.agentFilter?.value;
    const activeTab = getState().Layout.activeTab;

    if (permissionsCurrentGroup?.role === 'ROLE_ADMI') {
      const foundUser = members.find((member) => member.id === incomingUserId);

      if (agentIdInChatAgentFilter) {
        if (incomingUserId === agentIdInChatAgentFilter && (!foundUser || foundUser?.role === 'ROLE_ADMI'))
          dispatch(setChatAgentFilter(null));
        dispatch(getChats(true));
      }
    } else if (permissionsCurrentGroup?.role === 'ROLE_PUBLISHER') {
      dispatch(getChats(true));
    }

    if (['campaigns', 'reports'].includes(activeTab)) {
      const iframe = document.getElementById(activeTab);
      if (iframe) iframe.src = iframe.src;
    }
  } catch (error) {
    console.log(error.message);
  }
}

export const reassignedContactsCompleted = (payload) => async (dispatch, getState) => {
  try {
    const user = getState().Auth.user;
    const { userDisabled: incomingUserId } = payload;
    if (user?._id === incomingUserId) return;

    const permissionsCurrentGroup = getState().Auth.permissionsCurrentGroup;
    const agentIdInChatAgentFilter = getState().Chat.agentFilter?.value;
    const activeTab = getState().Layout.activeTab;

    if (permissionsCurrentGroup?.role === 'ROLE_ADMI') {
      if (agentIdInChatAgentFilter) {
        if (incomingUserId === agentIdInChatAgentFilter) dispatch(setChatAgentFilter(null));
        dispatch(getChats(true));
      }
    } else if (permissionsCurrentGroup?.role === 'ROLE_PUBLISHER') {
      dispatch(getChats(true));
    }

    if (['campaigns', 'reports'].includes(activeTab)) {
      const iframe = document.getElementById(activeTab);
      if (iframe) iframe.src = iframe.src;
    }
  } catch (error) {
    console.log(error.message);
  }
}

export const contactBlocked = (payload) => async (dispatch, getState) => {
  try {
    const { userId: incomingUserId, contactId: incomingContactId } = payload;

    const userId = getState().Auth.user.id;
    if (userId === incomingUserId) return;

    const chatsList = getState().Chat.chatsList;

    const foundChat = chatsList?.find((chat) => chat._id === incomingContactId);
    if (foundChat) dispatch(getChats(true));
  } catch (error) {
    console.log(error);
  }
}

export const contactDeleted = (payload) => async (dispatch, getState) => {
  try {
    const { userId: incomingUserId, contact: incomingContact } = payload;

    const userId = getState().Auth.user.id;
    if (userId === incomingUserId) return;

    dispatch(refreshContactChats(incomingContact));
  } catch (error) {
    console.log(error);
  }
}

export const contactCreatedOrEdited = (payload) => async (dispatch, getState) => {
  try {
    const {
      requesterUserId: incomingRequesterUserId,
      requesterUserRole: incomingRequesterUserRole,
      contact: incomingContact,
      resultOp
    } = payload;

    // incomingContact: updated info of the edited contact (without assignment history)
    // incomingRequesterUserId: contains the id of the user who made the edit
    // incomingRequesterUserRole: contains the role of the user who made the edit
    // oldUserTagId: contains the assignment id that the contact had before (will only appear if the assignment changes)
    // removedTagIdList: contains the ids of the tags removed from the contact (will only appear if there are removed tags)

    const currentUser = getState().Auth.user;
    const activeContact = getState().Contact.activeContact;
    const permissionsCurrentGroup = getState().Auth.permissionsCurrentGroup;
    const freeContactsNotVisibleByPublisher = getState().Group.currentGroup.freeContactsNotVisibleByPublisher;
    const chatSearchFilter = getState().Chat.filter;
    const chatAgentFilter = getState().Chat.agentFilter;
    const chatTagsFilter = getState().Chat.tagsFilter;
    const chatsList = getState().Chat.chatsList;
    const membersOfGroup = getState().Members.members;

    if (resultOp === "updated" && incomingContact.chat && currentUser.id !== incomingRequesterUserId) {
      const chatTagsFilterValues = chatTagsFilter?.map((tag) => tag.value) ?? [];
      const removedTagIdList = incomingContact.prevData.removedTagIdList ?? [];
      const allTagsIncomingContact = (incomingContact.tagIdList ?? []).concat(removedTagIdList);

      const userIdsAndUserTagIds = [{ id: currentUser.id, tagId: permissionsCurrentGroup.tagId }]
      membersOfGroup && membersOfGroup.forEach((member) => userIdsAndUserTagIds.push({ id: member.id, tagId: member.tagId }));

      const assignedAgent = incomingContact.userTagId && userIdsAndUserTagIds.find((member) => incomingContact.userTagId === member.tagId);
      const oldAssignedAgent = incomingContact.prevData.oldUserTagId && userIdsAndUserTagIds.find((member) => incomingContact.prevData.oldUserTagId === member.tagId);

      const updatedChat = { ...incomingContact };
      delete updatedChat.prevData;

      const foundChatIdx = chatsList.findIndex((chat) => chat.id === incomingContact.id);
      if (incomingRequesterUserRole === "ROLE_PUBLISHER" && permissionsCurrentGroup.role === "ROLE_PUBLISHER" && foundChatIdx >= 0) {
        dispatch(getChats(true));
      } else if (
        permissionsCurrentGroup.role === "ROLE_ADMI" ||
        (permissionsCurrentGroup.role === "ROLE_PUBLISHER" && incomingRequesterUserRole === "ROLE_ADMI" && (assignedAgent?.tagId === permissionsCurrentGroup.tagId || (!incomingContact.userTagId && !freeContactsNotVisibleByPublisher)))
      ) {
        if (!chatSearchFilter && !chatAgentFilter?.value && !(chatTagsFilterValues.length > 0)) {
          if (foundChatIdx >= 0) {
            const updatedChatsList = chatsList.map((chat, idx) => idx === foundChatIdx ? { ...chat, ...updatedChat } : chat);
            dispatch(setChats(updatedChatsList));
          }
          if (incomingContact.id === activeContact.id) dispatch(setActiveContact({ ...activeContact, ...updatedChat }));
        } else if (
          (incomingContact.name.toLowerCase().includes(chatSearchFilter.toLowerCase()) || (incomingContact.prevData.oldName && incomingContact.prevData.oldName.toLowerCase().includes(chatSearchFilter.toLowerCase())) || incomingContact.phone.includes(chatSearchFilter)) &&
          (permissionsCurrentGroup.role === "ROLE_PUBLISHER" || [assignedAgent?.id, oldAssignedAgent?.id, "unassigned", ""].includes(chatAgentFilter?.value)) &&
          (chatTagsFilterValues.every((tag) => allTagsIncomingContact.includes(tag)))
        ) {
          if (
            (incomingContact.prevData.oldName && incomingContact.prevData.oldName.toLowerCase().includes(chatSearchFilter.toLowerCase()) && !(incomingContact.name.toLowerCase().includes(chatSearchFilter.toLowerCase()))) ||
            (chatAgentFilter?.value && oldAssignedAgent?.id === chatAgentFilter.value) ||
            removedTagIdList.some((tag) => chatTagsFilterValues.includes(tag))
          ) {
            dispatch(getChats(true));
          } else if (foundChatIdx >= 0) {
            const updatedChatsList = chatsList.map((chat, idx) => idx === foundChatIdx ? { ...chat, ...updatedChat } : chat);
            dispatch(setChats(updatedChatsList));
            if (incomingContact.id === activeContact.id) dispatch(setActiveContact({ ...activeContact, ...updatedChat }));
          } else dispatch(getChats(true));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const setTagContact = (tag) => ({
  type: SET_TAG_LVL,
  payload: tag,
})

export const getTagContact = () => async (dispatch, getState) => {
  try {
    const activeContactId = getState().Contact.activeContact?.id;
    const res = await axios.get(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/contact-tags/${activeContactId}`);

    const afterActiveContactId = getState().Contact.activeContact?.id;
    if (activeContactId === afterActiveContactId) {
      if (res?._id) dispatch(setTagContact(res))
      else dispatch(setTagContact([]))
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const saveTagContact = (type, tagId, tagName) => async (dispatch, getState) => {
  try {
    const activeContact = getState().Contact.activeContact;
    const activeContactId = getState().Contact.activeContact?.id;
    const userId = getState().Auth.user.id;
    const currentGroupId = getState().Group.currentGroup?.id;
    const membersInGroup = getState().Members.members;

    let agentName = "Sin agente asignado"

    if(activeContact.userTagId && membersInGroup){
      let member = membersInGroup.find(
        (i) => i.tagId == activeContact.userTagId
      );
      if (!member
        && Array.isArray(activeContact.userTagIds)
        && activeContact.userTagIds.length > 0
        && activeContact.userTagIds[activeContact.userTagIds.length - 1].userTagId
        && activeContact.userTagIds[activeContact.userTagIds.length - 1].userTagId === activeContact.userTagId
        && activeContact.userTagIds[activeContact.userTagIds.length - 1].userId
      ) {
        member = membersInGroup.find(
          (m) => m.id === activeContact.userTagIds[activeContact.userTagIds.length - 1].userId
        );
      }
      agentName = member ? `${member.name}` : "Sin agente asignado"
    }

    const res = await axios.post(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/contact-tags`,
      {
        contactId: activeContactId,
        userId: userId,
        type: type,
        tagId: tagId,
        tagName: tagName,
        contactName: activeContact?.name,
        contactCountryCode: activeContact?.countryCode,
        contactPhone: activeContact?.phone,
        agentId: activeContact?.userTagId ? activeContact.userTagId : "",
        agentName: agentName,
      },
      {
        headers: { groupId: currentGroupId },
      }
    );
    const afterActiveContactId = getState().Contact.activeContact?.id;
    if (activeContactId === afterActiveContactId) {
      dispatch(getTagContact())
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const saveTagIdContact = (tagId, tagName) => async (dispatch, getState) => {
  try {
    const activeContact = getState().Contact.activeContact;
    const activeContactId = getState().Contact.activeContact?.id;
    const userId = getState().Auth.user.id;
    const userName = getState().Auth.user.name;
    const currentGroupId = getState().Group.currentGroup?.id;
    const membersInGroup = getState().Members.members;
    const tagsLevelFilter = getState().Chat.tagsLevelFilter;
    const tagsLvl = getState().Group.tagsLvl;

    let agentName = "Sin agente asignado"

    if(activeContact.userTagId && membersInGroup){
      let member = membersInGroup.find(
        (i) => i.tagId == activeContact.userTagId
      );
      if (!member
        && Array.isArray(activeContact.userTagIds)
        && activeContact.userTagIds.length > 0
        && activeContact.userTagIds[activeContact.userTagIds.length - 1].userTagId
        && activeContact.userTagIds[activeContact.userTagIds.length - 1].userTagId === activeContact.userTagId
        && activeContact.userTagIds[activeContact.userTagIds.length - 1].userId
      ) {
        member = membersInGroup.find(
          (m) => m.id === activeContact.userTagIds[activeContact.userTagIds.length - 1].userId
        );
      }
      agentName = member ? `${member.name}` : "Sin agente asignado"
    }

    await axios.post(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/contact-tags`,
      {
        contactId: activeContactId,
        userId: userId,
        userName: userName,
        tagId: tagId,
        contactName: activeContact?.name,
        contactCountryCode: activeContact?.countryCode,
        contactPhone: activeContact?.phone,
        agentId: activeContact?.userTagId ? activeContact.userTagId : "",
        agentName: agentName,
      },
      {
        headers: { groupId: currentGroupId },
      }
    );

    if(tagName.toLowerCase() === "testing-post-contratación"){
      const templates = getState().Templates.templates;
      const template = templates.find(template => template._id === "67ebf783805d31ae89b21100");
      if(template){
        dispatch(sendTemplate(template._id))
      }
    }

    const afterActiveContactId = getState().Contact.activeContact?.id;
    if (activeContactId === afterActiveContactId) {
      dispatch(getTagContact())
    }
    const afterGroupId = getState().Group.currentGroup?.id
    if (currentGroupId === afterGroupId) {
      const foundCurrentTag = tagsLvl.find(tag => tag._id === tagId)
      if(Array.isArray(tagsLevelFilter) && tagsLevelFilter.length > 0 && foundCurrentTag){
        for (var i=0; i<tagsLevelFilter.length; i++){
          const foundTag = tagsLvl.find(tag => tag._id === tagsLevelFilter[i].value)
          if(foundTag && (foundTag.type === foundCurrentTag.type) && (foundTag._id !== foundCurrentTag._id)){
            dispatch(
              getChats(
                true
              )
            );
            break;
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};